import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(2),
		marginBottom: theme.spacing(10),
	},
	page: {
		overflowY: 'auto',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

export default useStyles;
