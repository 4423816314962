import React from 'react';
import {
	Fab,
	TextField,
	Tooltip,
} from '@material-ui/core';
import {
	Search as SearchIcon,
} from '@material-ui/icons';

import useStyles from './HomePage.styled';
import ButtonLink from '../../components/ButtonLink';
import fields from '../../data/fields';

const HomePage = () => {
	const classes = useStyles();
	// Sets the default values based on what fields are used
	const [values, setValues] = React.useState(fields
		.reduce((o, key) => ({ ...o, [key.field]: key.default }), {}));

	const handleChange = name => (event) => {
		setValues({ ...values, [name]: event.target.value });
	};

	const searchQuery = Object.keys(values)
		.filter(key => values[key] !== '')
		.map(key => `${key}=${values[key]}`)
		.join('&');

	// console.log(searchQuery);

	return (
		<div className={classes.page}>
			<form className={classes.form} noValidate autoComplete="off">
				{fields.map(item => (
					<Tooltip
						key={item.field}
						title={item.helperText}
					>
						<TextField
							id={`mtg-${item.field}`}
							label={item.label}
							className={classes.textField}
							value={values[item.field]}
							onChange={handleChange(item.field)}
							margin="normal"
							fullWidth
						/>
					</Tooltip>
				))}
			</form>

			<Fab
				aria-label="search cards"
				className={classes.fab}
				color="primary"
				component={ButtonLink}
				disabled={searchQuery.length === 0}
				to={{
					pathname: '/search/',
					search: searchQuery,
					state: {
						modal: true,
					},
				}}
			>
				<SearchIcon />
			</Fab>
		</div>
	);
};

export default HomePage;
